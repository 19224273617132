export const UEDITOR_HOME_URL = 'http://xct.xair.cn/lib/ueditor/'
export const UEDITOR_JS_PATH = 'http://xct.xair.cn/lib/ueditor/ueditor.all.js'
export const PREFIX = '/plants'

export function getConfig() {
    let origin = window.location.origin.indexOf('localhost') > -1 ? 'http://xct.xair.cn' : window.location.origin
    return {
        UEDITOR_HOME_URL: `${origin}/lib/ueditor/`,
        UEDITOR_JS_PATH: `${origin}/lib/ueditor/ueditor.all.js`
    }
}
