<script>
import './ueditor.config.js'
import { getConfig } from './Ueditor.js'
let { UEDITOR_JS_PATH } = getConfig()
export default {
  render(h) {
    return h('div', {
      attrs: {
        id: this.randomId
      },
      class: 'xxxx-ueditor-box'
    })
  },
  data() {
    return {
      randomId: 'editor_' + Math.floor(Math.random() * 10000)
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    value(val) {
      if (val && this.ue && val !== this.contentText) {
        this.ue.ready(() => {
          this.setHtmlContent(val)
        })
      }
    }
  },
  methods: {
    setHtmlContent(content) {
      if (this.ue && document.getElementById(this.randomId)) {
        var textarea = document.createElement('textarea')
        textarea.innerHTML = content
        var text = textarea.innerText
        if (!text) return
        this.ue.setContent(text)
      }
    },
    ueReady() {
      this.ue.ready(() => {
        this.setHtmlContent(this.value)
        this.$emit('init', this.ue)
      })
    },
    scriptReady() {
      if (document.getElementById(this.randomId)) {
        this.ue = window.UE.getEditor(this.randomId, { enableAutoSave: false })
        this.ue.addListener('contentChange', this.onContentChange)
        this.ueReady()
      }
    },
    onContentChange() {
      let _html = this.ue.getContent()
      this.contentText = _html
      this.$emit('input', _html)
    }
  },
  created() {
    if (!document.getElementById('ueditorScript')) {
      let script = document.createElement('script')
      script.id = 'ueditorScript'
      script.addEventListener('load', this.scriptReady, false)
      script.src = UEDITOR_JS_PATH
      document.head.appendChild(script)
    } else {
      if (!window.UE || !window.UE.getEditor) {
        let script = document.getElementById('ueditorScript')
        script.addEventListener('load', this.scriptReady, false)
      } else {
        this.$nextTick(this.scriptReady)
      }
    }
  },
  beforeDestroy() {
    if (this.ue) {
      this.ue.removeListener('contentChange', this.onContentChange)
      this.ue.destroy()
      this.ue = null
    }
  }
}
</script>

<style>
.xxxx-ueditor-box {
  min-height: 360px;
  width: 100%;
  line-height: initial;
}
#edui1 {
  border-radius: 0;
}
[id$='_elementpath'] {
  display: none !important;
}
[id$='_toolbarbox'] {
  box-shadow: none !important;
}
[id$='_toolbarboxouter'] {
  background-color: white !important;
  background-image: inherit !important;
  border-radius: 0 !important;
  box-shadow: inherit !important;
}
</style>
